import axios from 'axios'
import showToastify from '../utils/toastify'
import "toastify-js/src/toastify.css"
import { find } from 'utils/dom'

export default function contatoForm() {
	const page = find("[js-page='contato']");
	if (!page) return;

	function showMessage(response) {
		const message = response.data ? response.data.message : response.message
		showToastify(message)
		contatoFormSubmit.reset()
		setTimeout(enabledBtn, 1000)
	}

	const btnSubmit = document.querySelector('.contact-form-3 button[type=submit]')

	function disabledBtn() {
		btnSubmit.disabled = true
		btnSubmit.classList.add('disabled')
	}

	function enabledBtn() {
		btnSubmit.disabled = false
		btnSubmit.classList.remove('disabled')
	}

	/** Válidar email */
	function IsEmail(email) {
		const regex = /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/;
		return regex.test(email);
	}

	const contatoFormSubmit = document.querySelector('.contact-form-3');


	contatoFormSubmit &&
		contatoFormSubmit.addEventListener('submit', function (event) {
			event.preventDefault()
			disabledBtn()

			const formData = new FormData()
			formData.append('nome', document.querySelector('.contact-form-3 .form-group input[name=nome]').value)
			formData.append('email', document.querySelector('.contact-form-3 .form-group input[name=email]').value)
			//formData.append('texto_deseja_receber', document.querySelectorAll('.contact-form-3 .form-group input[name=texto_deseja_receber]').checked.value)
			const inputs = document.querySelectorAll('.contact-form-3 .form-group input[name=texto_deseja_receber]')
			const inputCheckds = []
			inputs.forEach(input => {
				if (input.checked) {
					inputCheckds.push(input.value)
				}
			});


			const EmailErro = document.querySelector('.mailing-email-error');
			const NomeErro = document.querySelector('.mailing-nome-error');


			EmailErro.innerText = "";
			NomeErro.innerText = "";

			let Erros = 0;

			if (formData.get('email') == '') {
				EmailErro.innerText = "O campo é obrigatório.";
				Erros++;
			}

			if (formData.get('nome') == '') {
				NomeErro.innerText = "O campo é obrigatório.";
				Erros++;
			}

			if (!IsEmail(formData.get('email'))) {
				EmailErro.innerText = "Email inválido";
				Erros++;
			}

			if (Erros > 0) {
				enabledBtn();
				return;
			}

			formData.append('texto_deseja_receber', inputCheckds);

			const url = '/mailing/'
			axios
				.post(url, formData)
				.then((response) => showMessage(response))
				.catch((response) => showMessage(response))
		})
}