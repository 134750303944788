import axios from 'axios'
import showToastify from '../utils/toastify'
import 'toastify-js/src/toastify.css'
import { find } from 'utils/dom'

export default function denunciaForm() {
    const page = find("[js-page='denuncia']");
    if (!page) return;


    function showMessage(response) {
        const message = response.data ? response.data.message : response.message
        showToastify(message)
        denunciaFormSubmit.reset()
        setTimeout(enabledBtn, 1000)
    }

    const btnSubmit = document.querySelector('.denuncia-form-1 button[type=submit]')

    function disabledBtn() {
        btnSubmit.disabled = true
        btnSubmit.classList.add('disabled')
    }

    function enabledBtn() {
        btnSubmit.disabled = false
        btnSubmit.classList.remove('disabled')
    }


    const denunciaFormSubmit = document.querySelector('.denuncia-form-1');

    denunciaFormSubmit &&
        denunciaFormSubmit.addEventListener('submit', function (event) {
            event.preventDefault()
            disabledBtn()

            const formData = new FormData()
            formData.append('mensagem', document.querySelector('.denuncia-form-1 .form-group textarea[name=mensagem]').value)

            const url = '/denuncia/'

            axios
                .post(url, formData)
                .then((response) => showMessage(response))
                .catch((response) => showMessage(response))
        })

}