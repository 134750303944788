import axios from 'axios'
import showToastify from '../utils/toastify'
import 'toastify-js/src/toastify.css'
import { find } from 'utils/dom'

export default function contatoForm() {
	const page = find("[js-page='contato']");
	if (!page) return;


	function showMessage(response) {
		const message = response.data ? response.data.message : response.message
		showToastify(message)
		contatoFormSubmit.reset()
		setTimeout(enabledBtn, 1000)
	}

	const btnSubmit = document.querySelector('.contact-form-1 button[type=submit]')

	function disabledBtn() {
		btnSubmit.disabled = true
		btnSubmit.classList.add('disabled')
	}

	function enabledBtn() {
		btnSubmit.disabled = false
		btnSubmit.classList.remove('disabled')
	}


	const contatoFormSubmit = document.querySelector('.contact-form-1');

	contatoFormSubmit &&
		contatoFormSubmit.addEventListener('submit', function (event) {
			event.preventDefault()
			disabledBtn()

			const formData = new FormData()
			formData.append('nome', document.querySelector('.contact-form-1 .form-group input[name=nome]').value)
			formData.append('email', document.querySelector('.contact-form-1 .form-group input[name=email]').value)
			formData.append('telefone', document.querySelector('.contact-form-1 .form-group input[name=telefone]').value)
			formData.append('mensagem', document.querySelector('.contact-form-1 .form-group textarea[name=mensagem]').value)

			const EmailErro = document.querySelector('.contato-email-error');
			const NomeErro = document.querySelector('.contato-nome-error');


			EmailErro.innerText = "";
			NomeErro.innerText = "";

			let Erros = 0;

			if (formData.get('email') == '') {
				EmailErro.innerText = "O campo é obrigatório.";
				Erros++;
			}

			if (formData.get('nome') == '') {
				NomeErro.innerText = "O campo é obrigatório.";
				Erros++;
			}

			if (Erros > 0) {
				enabledBtn();
				return;
			}


			const url = '/fale-conosco/'

			axios
				.post(url, formData)
				.then((response) => showMessage(response))
				.catch((response) => showMessage(response))
		})

}